import React, { Component } from 'react';

export class Login extends Component {
    constructor(props) {
        super(props);
        document.title = "Login - NetCentric Consulting";
        this.state = {
            username: '',
            password: '',
            fields: {},
            errors: {},
            showConfirmation: false,
            showSpinner: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.submitLoginForm = this.submitLoginForm.bind(this);
        this.resetFormAfterLogin = this.resetFormAfterLogin.bind(this);
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    submitLoginForm(e) {
        e.preventDefault();
        this.setState({ showConfirmation: false });

        if (this.validateForm()) {
            // This login form currently does not do anything - there is no backend authentication. 
            // In the future, we might add a blog to this, and this form would allow users to log in,
            // but for now it is just a placeholder for future implementation
            this.setState({ showSpinner: true });
            setTimeout(this.resetFormAfterLogin, 2000);
        }
    }

    resetFormAfterLogin() {
        this.setState({ showConfirmation: true, showSpinner: false });
        this.resetForm();
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["username"]) {
            formIsValid = false;
            errors["username"] = "*Please enter your username.";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    resetForm() {
        let fields = {};
        fields["username"] = "";
        fields["password"] = "";
        this.setState({ fields: fields });
        this.setState({ showSpinner: false });
    }

    render() {
        return (
            <section id="login" className="h-100">
                <h1>Login</h1>
                <div className="content">
                    <div className="row">
                        <div className="col-sm-8 contactFormLeft">
                            <form method="post" name="contactForm" onSubmit={this.submitLoginForm} >
                                <input type="text" name="username" className="form-control" placeholder="Username" value={this.state.fields.username} onChange={this.handleChange} />
                                <div className="errorMsg">{this.state.errors.username}</div>
                                <input type="text" name="password" className="form-control" placeholder="Password" value={this.state.fields.password} onChange={this.handleChange} />
                                <div className="errorMsg">{this.state.errors.password}</div>
                                <input type="submit" className={this.state.showSpinner ? 'hidden' : 'btnContactSubmit btn btn-primary w-25 minWidth80'} value="Submit" />
                                <button className={this.state.showSpinner ? 'btnContactSubmit btn btn-primary w-25 minWidth130' : 'hidden'} type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status"></span>
                                    <span className=""> Processing...</span>
                                </button>
                                <div className={this.state.showConfirmation ? 'alert alert-danger mt-3' : 'hidden'}>Invalid credentials</div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}