import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Services } from './components/Services';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Login } from './components/Login';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/style_main.css'

const App = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <Layout>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/services' component={Services} />
                <Route path='/about' component={About} />
                <Route path='/contact' component={Contact} />
                <Route path='/login' component={Login} />
            </Switch>
        </Layout>
    );
}

export default withRouter(App);