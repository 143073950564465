import React, { Component } from 'react';
import '../css/footer.css';

export class Footer extends Component {
    state = {
        intervalId: 0,
        thePosition: false
    };

    componentDidMount() {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 150) {
                this.setState({ thePosition: true })
            } else {
                this.setState({ thePosition: false })
            }
        });
        window.scrollTo(0, 0);
    }

    onScrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderGoTopIcon = () => {
        if (this.state.thePosition) {
            return (
                <div className="go-top float-right" onClick={this.scrollToTop}>
                    Back to top
                </div>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
                <footer id="footer" className="footer">
                    &copy; 2021 NetCentric Consulting LLC - All Rights Reserved
                </footer>
            </React.Fragment>
        );
    }
}
